<template>
  <v-container id="dashboard" fluid tag="section">
    <!-- <v-text-field class="mt-4" :label="$t('datatable.write_to_search')" outlined append-icon="mdi-magnify"
      v-model="search">
    </v-text-field> -->
    <v-layout row wrap mt-4 pt-3 pb-3 pr-3 pl-3 ml-3>
      <v-flex xs12 sm4>

        <v-select v-model="filter.main_id" :items="gallery_categoriesfilter" item-text="title" item-value="id"
          label="القسم الرئيسي" required @change="initialize();filtersub=false;page=1;page=1; current_page=1"
          return-object persistent-hint v-on:input="initialize(`${filter.main_id}`)"></v-select>





      </v-flex>

      <v-flex pr-4 xs12 sm4 v-if="subCats.length>0">

        <v-select v-model="filter.sub_main_id" :items="subCats" item-text="title" item-value="id" label="القسم الفرعي"
          required @change="initialize();filtersub=true;page=1;current_page=1"
          v-on:input="initialize(`${filter.sub_main_id}`)"></v-select>




      </v-flex>


      <v-flex xs12 md2 pr-4>

        <v-btn color="blue" style="color:#fff" @click="filter.main_id=null;;page=1;page=1; current_page=1;initialize()">
          الكل</v-btn>


      </v-flex>
    </v-layout>
    <v-data-table :headers="headers" :items="desserts" sort-by="phone" class="elevation-1" :items-per-page="15">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> {{ $t("Drawer.libraryImage") }} </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="890px" persistent>
            <v-toolbar color="primary" dark> {{ $t("add_new") }}</v-toolbar>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" @click="editedIndex = -1 " dark class="mb-2" v-bind="attrs" v-on="on">
                {{ $t("add_new") }}
              </v-btn>
            </template>
            <v-stepper v-model="e1">

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="mb-12" flat>

                    <v-form ref="form" v-model="valid">
                      <v-container>

                        <v-row>
                          <v-col class="py-0" cols="12" sm="12" md="12">
                            <v-text-field v-model="editedItem.title" :rules="messageRules" required
                              :label="$t('datatable.title')" outlined>
                            </v-text-field>
                          </v-col>




                          <v-col class="py-0" cols="12" sm="12" md="12">
                            <v-textarea v-model="editedItem.description" :rules="messageRules" required
                              :label="$t('datatable.description')" outlined>
                            </v-textarea>
                          </v-col>

                          <v-col class="py-0" cols="4" sm="12" md="4">
                            <v-switch v-model="editedItem.visible" flat
                              :label="(editedItem.visible)=='1'?'نشر':'اخفاء'"></v-switch>
                          </v-col>






                        </v-row>


                        <hr>


                        <v-row row wrap>
                          <v-radio-group v-model="editedItem.gallery_categories[0]">



                            <v-col class="py-0" cols="12" sm="12" md="4" v-for="cat in gallery_categories" :key="cat"
                              v-if="cat.maincat_id==null">


                              <v-radio @change="upcat()" :label="cat.title" :value="cat"></v-radio>


                            </v-col>


                          </v-radio-group>
                        </v-row>

                        <p v-if="editedItem.gallery_categories.length >0"> اختر القسم الفرعي</p>


                        <v-row row wrap v-if="editedItem.gallery_categories.length >0">
                          <v-radio-group v-model="editedItem.gallery_categories[1]"
                            v-if="editedItem.gallery_categories.length >0">

                            <v-row row wrap>

                              <v-col cols="12" sm="12" md="4" v-for="cat in gallery_categories" :key="cat"
                                v-if="cat.maincat_id !== null && cat.maincat_id == editedItem.gallery_categories[0].id">


                                <v-radio :label="cat.title" :value="cat"></v-radio>


                              </v-col>
                            </v-row>

                          </v-radio-group>
                        </v-row>








                        <!-- <v-row row wrap>
                         
                            <v-col class="py-0" cols="12" sm="12" md="4" v-for="cat in gallery_categories" :key="cat">


                              <v-checkbox v-model="editedItem.gallery_categories" :label="cat.title" :value="cat"></v-checkbox>



                            </v-col>
                          </v-row>
 -->

                        <v-row row wrap v-if="editedIndex > -1 && editedItem.post !==null  ">
                          <v-col class="py-0" cols="6" sm="6" md="6">
                            <p>اسم المشارك : {{editedItem.post.name}}</p>
                            <p>هاتف المشارك : {{editedItem.post.phone}}</p>
                          </v-col>
                        </v-row>


                        <v-row row wrap>

                          <v-col class="py-0" cols="6" sm="12" md="6">
                            <v-text-field label="اختر الصورة" @click='pickImg' v-model='imageName'
                              prepend-icon='mdi-paperclip'>
                            </v-text-field>
                       
                            <input type="file" style="display: none" ref="image" accept="image/*"
                              @change="onFilePicked">
                            
                              <div v-if="loadSave">
                                                            %{{percent_img}}

                                                            <progress :value="percent_img"></progress>

                                                        </div>
                            

                          </v-col>

                          <v-col class="py-0" cols="6" sm="6" md="6">
                            <a data-fancybox="gallery" :data-caption="imageUrlx" :href="imageUrlx" class="">


                              <img :src="imageUrl" height="150" />
                            </a>
                          </v-col>

                          <v-col class="py-0" cols="6" sm="6" md="6">


                            <!-- <div v-if="loadSave"> -->

                            <!-- %{{percent}}

                            <progress :value="percent"></progress> -->


                            <!-- </div> -->
                          </v-col>
                        </v-row>


                      </v-container>



                    </v-form>

                  </v-card>

                  <v-btn color="primary" :loading="loadSave" @click="save()">
                    {{$t("save")}}
                  </v-btn>

                  <v-btn text @click="close()">
                    {{$t("close")}}
                  </v-btn>

                </v-stepper-content>


              </v-stepper-items>
            </v-stepper>
          </v-dialog>
        </v-toolbar>
      </template>


      <template v-slot:[`item.cat`]="{ item }">

        <span v-for="cat in   item.gallery_categories" :key="cat">
          <span>{{cat.title}}/</span>
        </span>



      </template>

      <template v-slot:[`item.status`]="{ item }">

        <v-chip v-if="item.visible==1" class="ma-2" style="color:#fff" color="green">
          تم النشر
        </v-chip>

        <v-chip v-if="item.visible==0" class="ma-2" style="color:#fff" color="red">
          في الانتظار
        </v-chip>


      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{cropdate(item.created_at)}}




      </template>



      <template v-slot:[`item.actions`]="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <button class="ml-5" @click="editItem(item)" v-if="!item.isDeleted" v-bind="attrs"
              v-on="on">{{ $t("edit") }} </button>
          </template>
          <span>{{ $t("edit") }} </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <button @click="deleteItem(item.id)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">
              {{$t('Delete')}}</button>
          </template>
          <span>{{$t('Delete')}}</span>
        </v-tooltip>

      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">{{ $t("Reloading") }}</v-btn>
      </template>
    </v-data-table>


    <v-pagination v-model="page" prev-icon="fas fa-angle-left fa-lg" color="#c7000b"
      next-icon="fas fa-angle-right fa-lg" style="    position: relative;top: 20px;" circle="" :length="pageCount">
    </v-pagination>
    <!-- <v-pagination v-else v-model="page_filter" prev-icon="fas fa-angle-left fa-lg" color="#c7000b"
      next-icon="fas fa-angle-right fa-lg" style="    position: relative;top: 20px;" circle="" :length="pageCount_filter">
    </v-pagination>


   -->

  </v-container>


</template>

<script>
  const size = 300;

  import {
    Fancybox
  } from "@fancyapps/ui";
  import "@fancyapps/ui/dist/fancybox.css";
  import axios from "axios";
  import Swal from "sweetalert2";

  export default {

    name: "videos",
    data() {
      return {
        percent: '',
        desserts: [],
        subCats: [],
        filter: {
          main_id: null,
          sub_main_id: null
        },

        e1: 1,
        imagesUrl: '',
        imageName: '',
        imageUrl: '',
        imageUrlx: '',
        file_name: null,



        page: 1,
        pageCount: 0,
        current_page: 1,

        filtersub: false,

        page_filter: 1,
        pageCount_filter: 0,
        current_page_filter: 1,

        last_page: 0,

        img_old: '',
        imageFile: '',
        gallery_categoriesfilter: [],
        imagesName: ' ',
        percent_img: '',


        image_url: '',
        dialog: false,
        loadSave: false,
        show_loading: false,

        editedIndex: -1,
        gallery_categories: [],
        editedItem: {

          image: {
            image_name: ''
          },
          id: "",
          title: "",
          post: "",
          description: "",
          views: "",
          gallery_categories: [],
          visible: true,
        },
        headers: [{
            text: 'id',
            value: 'id',
          }, {
            text: this.$t('datatable.title'),
            align: "start",
            value: "title"
          },
          {

            text: 'القسم',
            value: "cat",
            sortable: false
          },

          {
            text: 'تاريخ الرفع',
            align: "start",
            value: "date"
          },

          {

            text: 'الحاله',
            value: "status",
            sortable: false
          },

          {
            text: this.$t('Processes'),
            value: "actions",
            sortable: false
          }
        ],
        right: null,
        vvv: {},
        items: [],

        valid: false,
        rules: {
          required: value => !!value || this.$t("required_field"),
        },

        message: '',
        select: [v => !!v || this.$t("selectq")],
        messageRules: [
          v => !!v || this.$t("valMessage")
        ],
      }
    },
    watch: {
      selected: 'search by sub_cat_id',

    },
    computed: {

      selected: function () {
        return this.getMoreitems();
      }
    },
    methods: {
      cropdate(x) {
        return x.slice(0, 10);
      },
      sub_cats_fliter() {
        Fire.$emit('sub_cats_fliter');

      },
      cats_filter() {
        Fire.$emit('cats_fliter');


      },

      upcat() {

        this.editedItem.gallery_categories = [];
        this.editedItem.gallery_categories.push(this.editedItem.gallery_categories[0]);
      },
      getMoreitems() {

        if (this.current_page <= this.last_page) {
          this.current_page = this.page;


          this.initialize();
        }





      },
      validate() {
        valid: true;
      },

      getGalleryAlbumsfilter() {
        try {
          axios.get('GalleryAlbums/getMainCat', {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              }
            }).then(res => {
              // this.loading = false;
              this.gallery_categoriesfilter = res.data.data;


            })
            .catch(() => {
              // this.loading = false;
            });
        } catch (e) {

        }

      },

      getGalleryAlbums() {
        try {
          axios.get('GalleryAlbums', {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              }
            }).then(res => {
              this.loading = false;
              this.gallery_categories = res.data.data;


            })
            .catch(() => {
              this.loading = false;
            });
        } catch (e) {

        }

      },
      initialize() {

        if (this.filter.main_id == null) {

          this.loading = true;
          try {
            axios.get('gallery?page=' + this.current_page, {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                }
              }).then(res => {
                this.loading = false;
                this.desserts = res.data.data;
                this.last_page = res.data.last_page;
                this.pageCount = res.data.last_page;

              })
              .catch(() => {
                this.loading = false;
              });
          } catch (e) {

          }

        } else if (this.filter.main_id !== null && this.filtersub == false) {


          axios.get("/gallery/getByMainCatIdAdmin/" + this.filter.main_id.id + '/15?page=' + this.current_page).then(
            response => {

              this.loading = false;
              this.desserts = response.data.data.data;
              this.last_page = response.data.data.last_page;
              this.pageCount = response.data.data.last_page;

              this.sub_main_id = null;

              axios.get("/GalleryAlbums/subcat/" + this.filter.main_id.id)
                .then(res => {

                  this.subCats = res.data.data;
                  this.subCats.push({
                    "id": 1000000000,
                    "title": "الكل",
                    "relid": "38",
                    "maincat_id": "38",
                    "langid": "1",
                    "date": "1669293712"
                  });


                });





            })





        } else if (this.filter.main_id.id !== null && this.filtersub == true) {
          if (this.filter.sub_main_id == 1000000000) {
            axios.get("/gallery/getByMainCatIdAdmin/" + this.filter.main_id.id + '/15?page=' + this.current_page).then(
              response => {

                this.loading = false;
                this.desserts = response.data.data.data;
                this.last_page = response.data.data.last_page;
                this.pageCount = response.data.data.last_page;









              })
          } else {
            axios.get("/gallery/getByCatIdAdmin/" + this.filter.sub_main_id + '/15?page=' + this.current_page).then(
              response => {




                this.loading = false;
                this.desserts = response.data.data.data;
                this.last_page = response.data.data.last_page;
                this.pageCount = response.data.data.last_page;


              })
          }
        }



      },

      editItem(item) {
        this.dialog = true;
        this.e1 = 1;
        item.visible = (item.visible == 1) ? true : false;


        this.editedIndex = this.desserts.indexOf(item);
        this.$store.dispatch("library_uploads", item);
        this.editedItem = Object.assign({}, item);
        this.img_old = ''
        this.imageName = ''
        this.imageFile = null
        this.imageUrl = ''
        this.imageUrlx = '';


        this.img_old = this.editedItem.filename;
        this.imageUrl = this.Url + '/static/uploads/gallery/thumb_' + this.img_old;
        this.imageUrlx = this.Url + '/static/uploads/gallery/thumb_' + this.img_old;



      },
      pickFile() {
        this.$refs.file.click()
      },
      onPickfile(e) {

        this.file = this.$refs.file.files[0];
        let file = e.target.files;
        this.file = file[0]
        this.file_name = file[0].name
      },
      close() {
        this.dialog = false;
        this.e1 = 1;
        // this.filter.main_id = null;
        this.loadSave = false;

        if (this.filter.main_id == null) {

          this.initialize();
        } else {

          this.sub_cats_filter();
          this.cats_filter();



        }

        // this.$store.dispatch("book_uploads", this.vvv);


        this.img_old = ''
        this.imageName = ''
        this.imageFile = ''
        this.imageUrl = ''


        this.editedItem = {
          image: {
            image_name: ''
          },
          id: "",
          title: "",
          post: "",
          description: "",
          views: "",
          gallery_categories: [],
          visible: true,
        }

      },
      pickImg() {
        this.$refs.image.click()
      },
      pickFiles() {
        this.$refs.images.click()

      },
      onFilePicked(e) {
        const files = e.target.files
        if (files[0] !== undefined) {
          this.imageName = files[0].name
          if (this.imageName.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.imageUrl = fr.result
            this.imageFile = files[0]
          })
        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''
        }
      },
      deleteItem($id) {
        Swal.fire({
          title: this.$t('sure_process'),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no'),
        }).then(result => {
          if (result.value) {
            axios.delete("gallery/" + $id, {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                }
              })
              .then(() => {
                this.close();
                Swal.fire(this.$t('Successfully'), this.$t('done'), "success");
              })
              .catch(() => {
                Swal.fire(this.$t('not_successful'), this.$t('not_done'), "error");
              });
          }
        });
      },

      save() {
        if (this.$refs.form.validate()) {

          if (this.imageFile == '') {
            this.loadSave = false;
            Swal.fire({
              title: "يجب اختيار صوره",
              text: "",
              icon: "error",
              confirmButtonText: "اغلاق",
            });

          } else {
            this.loadSave = true;

            if (this.editedIndex > -1) {


              var updatedata = {



                title: this.editedItem.title,
                description: this.editedItem.description,
                gallery_categories: this.editedItem.gallery_categories,

                visible: (this.editedItem.visible) ? 1 : 0,


              }
              this.axios
                .put("gallery/update/" + this.editedItem.id, updatedata, {

                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                  },
                })
                .then(() => {
                  this.$refs.form.resetValidation();
                  if (this.imageFile != null) {


                    let formData = new FormData();

                    formData.append('url_image', this.imageFile);
                    var _this = this;

                    axios.post('libraryImage/uploadImage/' + this.editedItem.id, formData,
                    {
                                            headers: {
                                                Authorizations: "Bearer " + this.$store.state
                                                    .AdminInfo.token,
                                                'Content-Type': 'multipart/form-data',
                                            },
                                            onUploadProgress: function (uploadEvent) {

                                                _this.percent_img = Math.round((uploadEvent
                                                        .loaded / uploadEvent
                                                        .total) *
                                                    100);

                                                    console.log(_this.percent_img);


                                            }
                                        }
                     
                    )
                    this.img_old = ''
                    this.imageName = ''
                    this.imageFile = ''
                    this.imageUrl = ''
                  }
                  this.loadSave = false;

                  this.initialize();
                  this.close();

                  Swal.fire({
                    title: "تم تعديل ",
                    text: "",
                    icon: "success",
                    confirmButtonText: "اغلاق",

                  });
                })
                .catch(() => {
                  this.loadSave = false;
                  // this.initialize();
                  // this.close();

                  Swal.fire({
                    title: "تم تعديل ",
                    text: "",
                    icon: "success",
                    confirmButtonText: "اغلاق",

                  });
                  this.initialize();
                });


            } else {

              var savedate = {

                title: this.editedItem.title,
                description: this.editedItem.description,
                gallery_categories: this.editedItem.gallery_categories,
                visible: (this.editedItem.visible) ? 1 : 0,



              }


              axios
                .post("gallery/store", savedate, {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                  },
                })
                .then((res) => {



                

                  this.editedIndex = -1;
                  this.$refs.form.resetValidation();
                  if (this.imageFile != null) {
                    let formData = new FormData();

                    formData.append('url_image', this.imageFile);
                    var _this = this;

                    axios.post('gallery/uploadImage/' + res.data.data.id, formData,   {
                                            headers: {
                                                Authorizations: "Bearer " + this.$store.state
                                                    .AdminInfo.token,
                                                'Content-Type': 'multipart/form-data',
                                            },
                                            onUploadProgress: function (uploadEvent) {

                                                _this.percent_img = Math.round((uploadEvent
                                                        .loaded / uploadEvent
                                                        .total) *
                                                    100);

                                                    console.log(_this.percent_img);


                                            }
                                        }



               
                    ).then(response => {
                      this.editedItem = res.data.data;
                      Swal.fire({
                        title: 'تم رفع الصوره',
                        text: "",
                        icon: "success",
                        confirmButtonText: this.$t('close'),
                      });


                      this.imageFile = '';
                      this.imageFile = '';
                      this.imageName = '';
                      this.loadSave = false;

                      this.loadSave = false;
                      this.dialog = false;
                      this.close()
                      // this.initialize();
                    })
                  }



                }).catch((err) => {
                  err
                  this.loadSave = false;
                  this.$swal.fire({
                    title: "حدث خطا .. يرجي المحاوله لاحقا",
                    text: "",
                    icon: "error",
                    confirmButtonText: "اغلاق",
                  });
                });
            }
          }

        }
      },
      components: {

      },
      computed: {
        // UploudeImages: () => import("../../components/core/libraryupload.vue"),

      },
      // fdl

    },
    created() {




      Fire.$on('sub_cats_fliter', () => {

        if (this.filter.sub_main_id == 1000000000) {
          axios.get("/gallery/getByMainCatIdAdmin/" + this.filter.main_id.id + '/15?page=' + this.current_page)
            .then(
              response => {

                this.loading = false;
                this.desserts = response.data.data.data;
                this.last_page = response.data.data.last_page;
                this.pageCount = response.data.data.last_page;









              })
        } else {

          axios.get("/gallery/getByCatIdAdmin/" + this.filter.sub_main_id + '/15?page=' + this.current_page).then(
            response => {




              this.loading = false;
              this.desserts = response.data.data.data;
              this.last_page = response.data.data.last_page;
              this.pageCount = response.data.data.last_page;


            })
        }

      });

      Fire.$on('cats_fliter', () => {
        axios.get("/gallery/getByMainCatIdAdmin/" + this.filter.main_id.id + '/15?page=' + this.current_page).then(
          response => {

            this.loading = false;
            this.desserts = response.data.data.data;
            this.last_page = response.data.data.last_page;
            this.pageCount = response.data.data.last_page;



            axios.get("/GalleryAlbums/subcat/" + this.filter.main_id.id)
              .then(res => {

                this.subCats = res.data.data;
                this.subCats.push({
                  "id": 1000000000,
                  "title": "الكل",
                  "relid": "38",
                  "maincat_id": "38",
                  "langid": "1",
                  "date": "1669293712"
                });


              });





          })

      });
      this.getGalleryAlbums();
      this.getGalleryAlbumsfilter();
      this.initialize();
    }

  }
</script>